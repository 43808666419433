import React from "react";
import IndexSectionHeadersWhitePattern1 from "../components/headers-white-pattern/IndexSectionHeadersWhitePattern1";
import FooterSectionFootersWhitePattern1 from "../components/footers-white-pattern/FooterSectionFootersWhitePattern1";
import FeaturesSectionFaqsWhitePattern2 from "../components/faqs-white-pattern/FeaturesSectionFaqsWhitePattern2";
import PricingSectionPackaged from "../components/pricing-white-pattern/PricingSectionPackaged";
import { Topbar } from "../components/topbar/Topbar";

export default function Index() {
	return (
		<React.Fragment>
			<Topbar />
			<IndexSectionHeadersWhitePattern1 />
			<FeaturesSectionFaqsWhitePattern2 />
			<PricingSectionPackaged />
			<FooterSectionFootersWhitePattern1 />
		</React.Fragment>
	);
}
