import React from "react";

export default function FooterSectionFootersWhitePattern1() {
	return (
		<React.Fragment>
			<>
				<section
					className="bg-white"
					style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}
				>
					<div className="container px-4 mx-auto">
						<div className="pb-11 mx-auto max-w-4xl">
							<div className="flex flex-wrap justify-center -mx-3 lg:-mx-6">
								<div className="w-full md:w-auto p-3 md:px-6">
									<a
										className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium"
										href="/terms"
									>
										Terms
									</a>
								</div>
								<div className="w-full md:w-auto p-3 md:px-6">
									<a
										className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium"
										href="/contact"
									>
										Contact
									</a>
								</div>
								<div className="w-full md:w-auto p-3 md:px-6">
									<a
										className="inline-block text-lg md:text-xl text-coolGray-500 hover:text-coolGray-600 font-medium"
										href="/privacy"
									>
										Privacy
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="container px-4 mx-auto">
						<p className="py-10 md:pb-20 text-lg md:text-xl text-coolGray-400 font-medium text-center">© 2024 Makemybrand. All rights reserved.</p>
					</div>
				</section>
			</>
		</React.Fragment>
	);
}
