import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import _404SectionHttpCodesWhitePattern1 from '../components/http-codes-white-pattern/_404SectionHttpCodesWhitePattern1';

const meta = {
  title: 'Makemybrand\u0020\u002D\u0020Page\u0020Not\u0020Found',
  meta: [
    {
      name: 'description',
      content:
        'Make\u0020My\u0020Brand\u0020helps\u0020creators,\u0020freelancers,\u0020entrepreneurs,\u0020content\u0020creators,\u0020influencers,\u0020and\u0020professionals\u0020build\u0020and\u0020manage\u0020their\u0020personal\u0020brands\u0020effectively\u0020through\u0020our\u0020intuitive\u0020platform.',
    },
  ],
  link: [],
  style: [],
  script: [],
};

export default function _404() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <_404SectionHttpCodesWhitePattern1 />
    </React.Fragment>
  );
}

