import { TermsParent } from "./TermsParent";

export function Contact() {
	return (
		<TermsParent>
			<>
				<h1>Contact Us</h1>

				<h4>Email</h4>
				<p>
					For any inquiries or support, please reach out to us at <a href="mailto:support@makemybrand.ai">support@makemybrand.ai</a>.
				</p>
			</>
		</TermsParent>
	);
}
