import React from "react";
import { ROOT } from "../root";

export default function PluginHeader() {
	return (
		<React.Fragment>
			<>
				<section className="overflow-hidden">
					<div
						className="relative bg-white overflow-hidden"
						style={{ backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")', backgroundPosition: "center" }}
					>
						{/* <div className="pt-12 pb-28 md:pb-72"> */}
						<div className="pt-12">
							<div className="container px-4 mx-auto">
								<div className="mx-auto text-center max-w-3xl">
									<h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tighter">Boost Engagement In Your WordPress Site</h1>
									<p className="mb-8 mx-auto text-lg md:text-xl text-coolGray-500 font-medium max-w-3xl">Simplify Content Creation by Generating Visuals from the Wordpress Editor</p>
									<div className="flex flex-wrap justify-center">
										<div className="w-full md:w-auto py-1 md:py-0 md:mr-4">
											<a
												className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-green-500 rounded-md shadow-sm"
												href={ROOT + "/register"}
											>
												Start Creating
											</a>
										</div>
										<div className="w-full md:w-auto py-1 md:py-0" />
									</div>
								</div>
							</div>
						</div>

						<div className="pt-12">
							<div className="container px-4 mx-auto">
								<div className="mx-auto text-center max-w-3xl">
									<div className="text-center">
										<h3 className="mb-6 text-3xl md:text-5xl text-coolGray-900 font-bold tracking-tighter">What does the Makemybrand plugin do?</h3>
									</div>

									<p className="mb-8 mx-auto text-lg md:text-xl text-coolGray-500 font-medium max-w-3xl">The Makemybrand plugin adds visuals to your wordpress posts, using your brand colors and name. You can change the colors, texts and images however you like. It simplifies content creation and repurposes your blog content into images that you can share on social media.</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		</React.Fragment>
	);
}
