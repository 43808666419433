import { ROOT } from "../root";

export function Topbar() {
	return (
		<div className="sticky top-0 z-50 bg-white shadow-md">
			<div className="container px-4 mx-auto flex justify-between items-center py-4">
				<div className="flex space-x-4 items-center">
					<img
						src="/images/logo.svg"
						alt="Make My Brand"
						className="w-12 h-12"
					/>
					<a
						className="text-xl text-coolGray-800"
						href="/"
					>
						Make My Brand
					</a>
				</div>
				<div className="flex space-x-4">
					<a
						href={ROOT + "/register"}
						className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-600 transition duration-200"
					>
						Sign Up
					</a>
					<a
						href={ROOT + "/login"}
						className="px-4 py-2 text-coolGray-800 border border-coolGray-300 rounded-md hover:bg-coolGray-100 transition duration-200"
					>
						Login
					</a>
				</div>
			</div>
		</div>
	);
}
