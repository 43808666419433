import React from "react";
import FooterSectionFootersWhitePattern1 from "../components/footers-white-pattern/FooterSectionFootersWhitePattern1";
import PricingSectionPackaged from "../components/pricing-white-pattern/PricingSectionPackaged";
import { Topbar } from "../components/topbar/Topbar";

export default function Pricing() {
	return (
		<React.Fragment>
			<Topbar />
			<PricingSectionPackaged />
			<FooterSectionFootersWhitePattern1 />
		</React.Fragment>
	);
}
