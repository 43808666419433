import FooterSectionFootersWhitePattern1 from "../footers-white-pattern/FooterSectionFootersWhitePattern1";
import { Topbar } from "../topbar/Topbar";

export function TermsParent({ children }) {
	return (
		<>
			<Topbar />
			<section className="overflow-hidden my-24">
				<div
					className="relative bg-white overflow-hidden"
					style={{
						backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
						backgroundPosition: "center",
						lineHeight: 1.5,
					}}
				>
					<div className="pt-12">
						<div className="container px-4 mx-auto">
							<div className="mx-auto max-w-3xl">{children}</div>
						</div>
					</div>
				</div>
			</section>
			<FooterSectionFootersWhitePattern1 />
		</>
	);
}
